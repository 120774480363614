import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const Abacus = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M57.1661 5.72135H9.34036C7.53255 5.72135 6.06328 7.19062 6.06328 8.99844V56.8276C6.06328 58.6354 7.53255 60.1047 9.34036 60.1047H57.1661C58.974 60.1047 60.4466 58.6354 60.4466 56.8276V8.99844C60.4466 7.19062 58.974 5.72135 57.1661 5.72135ZM59.431 56.8276C59.431 58.0768 58.4154 59.0891 57.1661 59.0891H9.34036C8.09115 59.0891 7.07891 58.0768 7.07891 56.8276V8.99844C7.07891 7.7526 8.09115 6.73698 9.34036 6.73698H57.1661C58.4154 6.73698 59.431 7.7526 59.431 8.99844V56.8276Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M20.9456 19.0464C20.9456 19.219 20.9591 19.3883 20.9828 19.5542H15.082C14.8044 19.5542 14.5742 19.3273 14.5742 19.0464C14.5742 18.7654 14.8044 18.5385 15.082 18.5385H20.9828C20.9591 18.7044 20.9456 18.8737 20.9456 19.0464Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M36.9044 32.913C36.9044 33.0857 36.918 33.2549 36.9417 33.4208H24.9099V35.3099C24.9099 35.5909 24.6797 35.8177 24.4021 35.8177C24.1245 35.8177 23.8943 35.5909 23.8943 35.3099V33.4208H15.082C14.8044 33.4208 14.5742 33.194 14.5742 32.913C14.5742 32.632 14.8044 32.4052 15.082 32.4052H23.8943V30.5161C23.8943 30.2352 24.1211 30.0083 24.4021 30.0083C24.6831 30.0083 24.9099 30.2352 24.9099 30.5161V32.4052H36.9417C36.918 32.5711 36.9044 32.7404 36.9044 32.913Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M51.9323 32.913C51.9323 33.194 51.7055 33.4208 51.4245 33.4208H43.7802C43.8039 33.2549 43.8174 33.0857 43.8174 32.913C43.8174 32.7404 43.8039 32.5711 43.7802 32.4052H51.4245C51.7055 32.4052 51.9323 32.632 51.9323 32.913Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M51.9323 19.0464C51.9323 19.3273 51.7055 19.5542 51.4245 19.5542H42.013V21.4432C42.013 21.7242 41.7862 21.951 41.5052 21.951C41.2242 21.951 40.9974 21.7242 40.9974 21.4432V19.5542H33.7628V21.4432C33.7628 21.7242 33.5326 21.951 33.2549 21.951C32.9773 21.951 32.7471 21.7242 32.7471 21.4432V19.5542H27.818C27.8417 19.3883 27.8552 19.219 27.8552 19.0464C27.8552 18.8737 27.8417 18.7044 27.818 18.5385H32.7471V16.6495C32.7471 16.3685 32.974 16.1417 33.2549 16.1417C33.5359 16.1417 33.7628 16.3685 33.7628 16.6495V18.5385H40.9974V16.6495C40.9974 16.3685 41.2242 16.1417 41.5052 16.1417C41.7862 16.1417 42.013 16.3685 42.013 16.6495V18.5385H51.4245C51.7055 18.5385 51.9323 18.7654 51.9323 19.0464Z"
        fill="#1C4259"
      />
      <path
        d="M51.9323 46.7797C51.9323 47.0607 51.7055 47.2875 51.4245 47.2875H44.7214V49.1766C44.7214 49.4575 44.4945 49.6844 44.2135 49.6844C43.9326 49.6844 43.7057 49.4575 43.7057 49.1766V47.2875H34.7344C34.7581 47.1216 34.7716 46.9523 34.7716 46.7797C34.7716 46.607 34.7581 46.4378 34.7344 46.2719H43.7057V44.3828C43.7057 44.1052 43.9326 43.875 44.2135 43.875C44.4945 43.875 44.7214 44.1052 44.7214 44.3828V46.2719H51.4245C51.7055 46.2719 51.9323 46.4987 51.9323 46.7797Z"
        fill="#1C4259"
      />
      <path
        d="M27.8586 46.7797C27.8586 46.9523 27.8721 47.1216 27.8958 47.2875H15.082C14.8044 47.2875 14.5742 47.0607 14.5742 46.7797C14.5742 46.4987 14.8044 46.2719 15.082 46.2719H27.8958C27.8721 46.4378 27.8586 46.607 27.8586 46.7797Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M27.8552 19.0464C27.8552 19.219 27.8417 19.3883 27.818 19.5542C27.5742 21.2198 26.1354 22.5029 24.4021 22.5029C22.6688 22.5029 21.2299 21.2198 20.9828 19.5542C20.9591 19.3883 20.9456 19.219 20.9456 19.0464C20.9456 18.8737 20.9591 18.7044 20.9828 18.5385C21.2299 16.8729 22.6688 15.5898 24.4021 15.5898C26.1354 15.5898 27.5742 16.8729 27.818 18.5385C27.8417 18.7044 27.8552 18.8737 27.8552 19.0464Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M43.8174 32.913C43.8174 33.0857 43.8039 33.2549 43.7802 33.4208C43.5331 35.0865 42.0943 36.3695 40.3609 36.3695C38.6276 36.3695 37.1888 35.0865 36.9417 33.4208C36.918 33.2549 36.9044 33.0857 36.9044 32.913C36.9044 32.7404 36.918 32.5711 36.9417 32.4052C37.1888 30.7396 38.6276 29.4565 40.3609 29.4565C42.0943 29.4565 43.5331 30.7396 43.7802 32.4052C43.8039 32.5711 43.8174 32.7404 43.8174 32.913Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M34.7716 46.7797C34.7716 46.9523 34.7581 47.1216 34.7344 47.2875C34.4872 48.9531 33.0484 50.2362 31.3151 50.2362C29.5818 50.2362 28.143 48.9531 27.8958 47.2875C27.8721 47.1216 27.8586 46.9523 27.8586 46.7797C27.8586 46.607 27.8721 46.4378 27.8958 46.2719C28.143 44.6062 29.5818 43.3266 31.3151 43.3266C33.0484 43.3266 34.4872 44.6062 34.7344 46.2719C34.7581 46.4378 34.7716 46.607 34.7716 46.7797Z"
        fill={secondary}
        className="fill-secondary"
      />
    </svg>
  );
};

export default Abacus;
